import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MainLayout from "./components/layouts/MainLayout";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import TermsAndConditions  from "./pages/Privacy";

function App() {
  return (
    <div className="font-[Poppins]">
      {/* <TawkMessengerReact
        propertyId="653384a2f2439e1631e6acd6"
        widgetId="1hd8js7fu"
      /> */}
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<TermsAndConditions />} />
            <Route path="/support" element={<TermsAndConditions />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
