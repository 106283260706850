import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import SEO from "../components/SEO";
import CompanySlide from "../components/CompanySlide";
import Upgrade from "../components/modals/Upgrade";
import Account from "../components/modals/Account";
import LoggingIn from "../components/modals/LoggingIn";

function Home() {
  const [modal, setModal] = useState(false);
  const [accountModal, setAcountModal] = useState(false);
  const [upgradeModal, setUpgardeModal] = useState(false);

  useEffect(() => {
    // Wait for 3 seconds
    setModal(true);
    setTimeout(() => {
      setModal(false);
    }, 3000);
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="text-gray-600">
      <SEO
        title="GigShark"
        description="Empowers Service Providers on Top Freelancing Platforms"
        name="GigShark"
        type="description"
      />
      {/* Hero ++++++++++++++++++++++++++ */}
      <div className="custom-gradient">
        <div className="container lg:px-10 m-auto space-y-6 md:space-y-0 lg:flex md:gap-6 lg:items-center lg:gap-10 py-5 md:py-20 justify-between">
          <div className="md:6/12 lg:w-5/12 mb-20">
            <motion.p
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -300, opacity: 0 }}
              className="text-sm lg:text-lg font-medium text-[#FCF4FE] text-center lg:text-left lg:w-[26rem] "
            >
              Find Your Perfect Freelance Job, Faster. Smarter. Easier
            </motion.p>
            <h1 className="text-[#fff] text-center lg:text-left text-xl md:text-3xl lg:text-4xl font-[Gilroy-Bold] font-semibold mb-4 lg:w-[20rem] lg:tracking-[.10em]">
              Welcome to GigShark: The Future of Freelancing
            </h1>
            <motion.p
              initial={{ y: 300, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -300, opacity: 0 }}
              className="lg:w-[30rem] text-sm lg:text-base font-light mb-5 text-[#fff] text-center lg:text-left px-4 lg:px-0"
            >
              At Gigshark, we empower freelancers to score their dream jobs with
              AI-driven assistance designed to simplify and enhance your job
              search experience. Whether you’re seasoned professional or just
              starting, GigShark is your go-to platform for finding the perfect
              gig in no time
            </motion.p>

            <div className="md:6/12 lg:w-7/12 flex flex-col md:flex-row justify-center lg:justify-start items-center gap-3 mb-9 ">
              <button className="w-[12rem] lg:w-auto text-sm lg:text-lg   font-semibold transition duration-200 ">
                <img src="butt1.svg" className="w-full " alt="" />
              </button>

              <button className="w-[12rem] lg:w-auto text-sm lg:text-lg  font-semibold transition duration-200">
                <img src="butt2.svg" className="w-full" alt="" />
              </button>
            </div>
          </div>
          <div className="md:6/12 px-5 lg:px-0 pb-20">
            <div className="bg-[#fff] flex  gap-x-2 items-start p-4 rounded-lg w-[18rem] md:w-[28rem] -mt-12 ml-5 sm:ml-9 lg:-ml-11 z-100 shadow-lg absolute overflow-hidden">
              <img src="./robo.svg" alt="" className="w-10 lg:w-14" />
              <div>
                <h2 className="text-sm text-[#1A1A1A] md:text-lg mx-auto font-semibold ">
                  Get Jobs Easily
                </h2>
                <p className="font-extralight text-[#808080] text-xs sm:text-sm lg:text-base">
                  Leverage on our AI solutons to find keywords that clients look
                  out for when looking for a freelancer to work with.
                </p>
              </div>
            </div>
            <div className="w-full  md:flex gap-3 ">
              <img src="../pic1.png" className="rounded-md w-auto" alt="i" />
              <div className="space-y-3 mt-3 lg:mt-0 w-full">
                <img src="../pic2.png" className="rounded-md w-full" alt="i" />
                <img src="../pic3.png" className="rounded-md w-full" alt="i" />
              </div>
            </div>
            <div className="flex lg:justify-center ">
              <div className="bg-[#fff] flex  gap-x-2 items-start p-4 rounded-lg w-[18rem] md:w-[27rem] -mt-12 -ml-5  lg:-ml-20 z-100 shadow-lg absolute">
                <img src="./money.svg" alt="" />
                <div>
                  <h2 className="text-sm text-[#1A1A1A] md:text-lg mx-auto font-semibold ">
                    Make Money Easily
                  </h2>
                  <p className="font-extralight text-[#808080] text-xs sm:text-sm lg:text-base">
                    With our soluitons, you have a sure guarantee of getting
                    quality jobs and high earnings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CTA +++++++++++++++++++++++++++++ */}

      <section className="mx-auto  justify-center  py-10 bg-[#FFF]">
        <h2 className="text-xl  text-[#1A1A1A] text-center md:text-3xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] px-3">
          GigShark{" "}
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            Empowers Service Providers
          </span>{" "}
          on Top Freelancing Platforms
        </h2>
        <div className="w-full pt-7">
          <CompanySlide />
        </div>
      </section>

      {/* About ***************************** */}
      <div
        id="about"
        className=" bg-[#fff] py-16 flex flex-col items-center  justify-center gap-x-1 px-5 lg:px-20"
      >
        <div className="container  space-y-6 md:space-y-0 lg:flex md:gap-6 lg:items-start py-5 md:py-10">
          <div className="lg:w-6/12">
            <p className="pb-3 font-light bg-gradient-to-b  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent lg:text-lg text-center lg:text-left">
              ABOUT GIGSHARK
            </p>
            <h2 className="text-xl text-[#212529] font-medium md:text-4xl lg:text-5xl font-[Gilroy-Bold] lg:tracking-[.10em] text-center lg:text-left">
              GigShark is an{" "}
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                AI driven solution aimed at empowering Freelancers
              </span>
            </h2>
            <p className="mt-6 font-extralight text-[#808080] text-sm md:text-xl text-center lg:text-left">
              GigShark enables freelancers across the globe earn more money by
              getting jobs leveraging on AI, be more productive and do their
              best work which would need to positive reviews from clients and
              thereby boosting the reputation of the freelancer
            </p>
          </div>

          <div className="sm:w-[35rem] lg:w-auto">
            <img src="../pic4.png" alt="i" />
          </div>
        </div>

        <div className="w-full py-10 grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="text-center space-y-2">
            <h2 className="text-3xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent font-medium md:text-4xl font-[Gilroy-Bold] ">
              10,000+
            </h2>
            <p className="mt-6 font-light text-[#808080] text-sm lg:text-xl">
              Registered Freelancers
            </p>
          </div>

          <div className="text-center space-y-2">
            <h2 className="text-3xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent font-medium md:text-4xl font-[Gilroy-Bold] ">
              5,000+
            </h2>
            <p className="mt-6 font-light text-[#808080] text-sm lg:text-xl">
              Hot Jobs
            </p>
          </div>

          <div className="text-center space-y-2">
            <h2 className="text-3xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent font-medium md:text-4xl font-[Gilroy-Bold] ">
              5,000+
            </h2>
            <p className="mt-6 font-light text-[#808080] text-sm lg:text-xl">
              Daily Active Users
            </p>
          </div>
        </div>
      </div>

      {/* How Gigshark works ********************************** */}
      <div
        className="w-full bg-center bg-cover h-auto "
        style={{ "background-image": "url(./hiwbg.svg)" }}
      >
        <section className="mx-auto py-20 px-4 lg:px-24  flex flex-col  items-center  justify-center  ">
          <h2 className="text-2xl text-[#FFF] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
            How GigShark Works
          </h2>
          <div className="w-full py-5 lg:py-10 grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 ">
            <div className="bg-[#fff] space-y-4 p-4 md:p-8 rounded-lg flex flex-col justify-center items-center shadow-md text-center">
              <img src="./con1.svg" alt="" />
              <img src="./Create.svg" alt="" />
              <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
                Start by setting up a detailed profile that highlights your
                skills, experience, and job preferences.
              </p>
            </div>
            <div className="w-full md:col-span-2 grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-2 ">
              <div className="bg-[#fff] space-y-4 p-4 md:p-8 rounded-lg flex flex-col justify-center items-center shadow-md text-center">
                <img src="./con2.svg" alt="" />
                <img src="./Get.svg" alt="" />
                <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
                  Our AI scans and analyzes job listings to present you with the
                  best opportunities.
                </p>
              </div>
              <div className="bg-[#fff] space-y-4 p-4 md:p-8 rounded-lg flex flex-col justify-center items-center shadow-md text-center">
                <img src="./con3.svg" alt="" />
                <img src="./Optimize.svg" alt="" />
                <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
                  Enhance your proposals, resumes, and cover letters with
                  relevant keywords and industry-specific phrases.
                </p>
              </div>
              <div className="bg-[#fff] space-y-4 p-4 md:p-8 rounded-lg flex flex-col justify-center items-center shadow-md text-center">
                <img src="./con4.svg" alt="" />
                <img src="./Apply.svg" alt="" />
                <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
                  Apply to jobs directly from the platform and track your
                  application status effortessly
                </p>
              </div>
              <div className="bg-[#fff] space-y-4 p-4 md:p-8 rounded-lg flex flex-col justify-center items-center shadow-md text-center">
                <img src="./con5.svg" alt="" />
                <img src="./Land.svg" alt="" />
                <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
                  Secure the job that perfectly matches your skills and
                  expectations.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Why choose GigShark *********************************** */}
      <section className="mx-auto  bg-[#FCF4FE] lg:py-20 px-4 lg:px-24  flex flex-col  items-center  justify-center  ">
        <div className="w-full pt-5 lg:pt-10 grid grid-cols-1  md:grid-cols-3 gap-0.5">
          <div className=" py-4 md:py-8 rounded-lg">
            <h2 className="text-2xl text-[#1A1A1A] md:text-3xl lg:text-5xl pt-10 mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              Why choose <br />
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                GigShark
              </span>
            </h2>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                01
              </span>
            </h2>
            <img src="./01.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              our AI matches you with opportunities that best fit your skills
              and preferences.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                02
              </span>
            </h2>
            <img src="./02.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Receive tailored keyword recommendations that increase your
              visibility and relevance in your job applications.
            </p>
          </div>
        </div>
        <div className="w-full pb-5 lg:pb-10 lg:flex pt-0.5 gap-0.5">
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded col-span-3">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                03
              </span>
            </h2>
            <img src="./03.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Stay ahead of the competition with instant notifications about the
              latest Projects and Gig openings in your field.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded lg:w-[45rem]">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                04
              </span>
            </h2>
            <img src="./04.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Craft compelling proposals tailored to each job posting for
              maximum impact.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded col-span-2">
            <h2 className="text-2xl bg-gradient-to-br from-[#DB00C5]  to-[#c300bdd5]  bg-clip-text text-transparent md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]  ">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                05
              </span>
            </h2>
            <img src="./05.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm lg:text-lg">
              Save time with ready-to-use templates that can be personalized to
              suit any job application
            </p>
          </div>
        </div>
      </section>

      {/* Features *********************************** */}
      <section
        id="features"
        className="mx-auto  bg-[#F3F4FE] py-28 px-4 lg:px-24  flex flex-col  items-center  justify-center  "
      >
        <h2 className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]   bg-clip-text text-transparent font-light  text-center text-base mb-4 mx-auto">
          FEATURES
        </h2>
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
          GigShark is a{" "}
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]   bg-clip-text text-transparent">
            powerful soluiton
          </span>
          , Redefining the way Freelancers work.
        </h2>
        <p className="mt-5 text-center font-light text-[#62747d] text-sm sm:w-[40rem] lg:w-[48rem] px-4">
          GigShark enables freelancers across the globe earn more money by
          getting jobs leveraging on AI, be more productive and do their best
          work which would need to positive reviews from clients and thereby
          boosting the reputation of the freelancer
        </p>
        <div className="w-full py-5 lg:py-10 grid grid-cols-1 gap-8 md:grid-cols-2 ">
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon1.svg" alt="" />
            <img src="./Web.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              GigShark enables freelancers across the globe earn more money by
              getting jobs leveraging on.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon2.svg" alt="" />
            <img src="./Whatsapp.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              GigShark enables freelancers across the globe earn more money by
              getting jobs leveraging on.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon3.svg" alt="" />
            <img src="./Real.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              GigShark enables freelancers across the globe earn more money by
              getting jobs leveraging on.
            </p>
          </div>
          <div className="bg-[#fff] space-y-2 p-4 md:p-8 rounded-lg">
            <img src="./icon4.svg" alt="" />
            <img src="./AI.svg" alt="" />
            <p className="mt-6 font-light text-[#62747d] text-sm">
              GigShark enables freelancers across the globe earn more money by
              getting jobs leveraging on.
            </p>
          </div>
        </div>
      </section>

      {/* Testimony **************** *****************/}
      <section className="mx-auto  bg-[#FFF] py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 ">
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] tracking-[.12em] sm:w-[40rem] lg:w-[40rem] ">
          “Real Stories from{" "}
          <span className="bg-gradient-to-b  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            Real Customers
          </span>
          ”
        </h2>
        <h2 className="text-base bg-gradient-to-b  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]   bg-clip-text text-transparent font-light  text-center md:text-base my-4 mx-auto">
          Get inspired by these stories.
        </h2>

        <div className="w-full py-16 grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 px-5">
          <div className="bg-[#fff] space-y-2 p-5 lg:p-10 rounded-lg shadow-sm border text-center">
            <img src="./img.svg" alt="" className="mx-auto w-14 lg:w-auto" />

            <div className="flex gap-x-2 items-start  pt-5">
              <img src="./quote.svg" alt="" className="mx-auto" />
              <p className=" font-light text-[#1A1A1A] text-sm">
                Lorem ipsum dolor sit amet consectetur. Nulla nibh interdum non
                habitant tincidunt massa. Nunc scelerisque enim interdum vitae
                vulputate vestibulum orci. In ultrices amet nibh mattis faucibus
                lacinia dui. Velit.
              </p>
            </div>

            <div className="pt-7">
              <h2 className="text-sm text-[#1A1A1A] text-center md:text-lg mx-auto font-semibold ">
                Engr. Umer
              </h2>
              <p className="font-light text-[#808080] text-center text-xs">
                Software Developer
              </p>
            </div>
          </div>
          <div className="bg-[#fff] space-y-2 p-5 lg:p-10 rounded-lg shadow-sm border text-center lg:-mt-10 lg:mb-10">
            <img src="./img.svg" alt="" className="mx-auto w-14 lg:w-auto" />

            <div className="flex gap-x-2 items-start  pt-5">
              <img src="./quote.svg" alt="" className="mx-auto" />
              <p className=" font-light text-[#1A1A1A] text-sm">
                Lorem ipsum dolor sit amet consectetur. Nulla nibh interdum non
                habitant tincidunt massa. Nunc scelerisque enim interdum vitae
                vulputate vestibulum orci. In ultrices amet nibh mattis faucibus
                lacinia dui. Velit.
              </p>
            </div>

            <div className="pt-7">
              <h2 className="text-sm text-[#1A1A1A] text-center md:text-lg mx-auto font-semibold ">
                Engr. Umer
              </h2>
              <p className="font-light text-[#808080] text-center text-xs">
                Software Developer
              </p>
            </div>
          </div>
          <div className="bg-[#fff] space-y-2 p-5 lg:p-10 rounded-lg shadow-sm border text-center">
            <img src="./img.svg" alt="" className="mx-auto w-14 lg:w-auto" />

            <div className="flex gap-x-2 items-start  pt-5">
              <img src="./quote.svg" alt="" className="mx-auto" />
              <p className=" font-light text-[#1A1A1A] text-sm">
                Lorem ipsum dolor sit amet consectetur. Nulla nibh interdum non
                habitant tincidunt massa. Nunc scelerisque enim interdum vitae
                vulputate vestibulum orci. In ultrices amet nibh mattis faucibus
                lacinia dui. Velit.
              </p>
            </div>

            <div className="pt-7">
              <h2 className="text-sm text-[#1A1A1A] text-center md:text-lg mx-auto font-semibold ">
                Engr. Umer
              </h2>
              <p className="font-light text-[#808080] text-center text-xs">
                Software Developer
              </p>
            </div>
          </div>
        </div>

        <button className="flex items-center justify-center gap-x-2 lg:w-[20rem]  text-sm custom-gradient transition-colors duration-300  p-3 rounded-md hover:text-[#fff] text-[#FFF] font-semibold uppercase hover:bg-[#c0c5c5] mx-auto ">
          EXPLORE ALL CUSTOMER STORIES
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.207 18.7069C17.0184 18.8891 16.7658 18.9899 16.5036 18.9876C16.2414 18.9853 15.9906 18.8801 15.8052 18.6947C15.6198 18.5093 15.5146 18.2585 15.5123 17.9963C15.51 17.7341 15.6108 17.4815 15.793 17.2929L20.086 12.9999H2.5C2.23478 12.9999 1.98043 12.8946 1.79289 12.707C1.60536 12.5195 1.5 12.2651 1.5 11.9999C1.5 11.7347 1.60536 11.4803 1.79289 11.2928C1.98043 11.1053 2.23478 10.9999 2.5 10.9999H20.086L15.793 6.70692C15.6108 6.51832 15.51 6.26571 15.5123 6.00352C15.5146 5.74132 15.6198 5.49051 15.8052 5.3051C15.9906 5.11969 16.2414 5.01452 16.5036 5.01224C16.7658 5.00997 17.0184 5.11076 17.207 5.29292L23.207 11.2929C23.3945 11.4804 23.4998 11.7348 23.4998 11.9999C23.4998 12.2651 23.3945 12.5194 23.207 12.7069L17.207 18.7069Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </section>

      {/* Pricing ************** **************** */}
      <div
        id="pricing"
        className=" bg-[#F3F4FE] py-16 px-5 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 "
      >
        <div className="container  mx-auto py-10">
          <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
            <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
              Affordable Pricing{" "}
            </span>
            for Everyone
          </h2>
          <p className="mt-5 text-center font-light text-[#1a1a1a] text-sm sm:w-[40rem] lg:w-[48rem] mx-auto ">
            Sit lorem urna morbi tortor mi vitae. Eu eu pellentesque neque sit
            in nec vestibulum mi diam. Adipiscing pharetra tempus vitae orci
            eget lorem pellentesque. Dui et ipsum ultrices id diam hend.
          </p>

          <button className="w-[15rem] flex justify-center my-7 text-sm  transition-colors duration-300 p-px  rounded-md  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  font-bold uppercase hover:bg-[#F5F5F5] mx-auto ">
            <div className="bg-white w-full p-3 rounded-md">
              <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                GET STARTED FOR FREE
              </span>
            </div>
          </button>

          <div className=" grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 lg:grid-cols-3 ">
            <div className="flex flex-col items-center justify-center bg-[#fff] pt-5 pb-10 rounded-md shadow">
              <div className="w-full text-center space-y-2 border-b px-8 py-4">
                <h1 className="text-xs sm:text-base lg:text-xl font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                  FREE PLAN
                </h1>{" "}
                <p className="text-xs sm:text-base lg:text-xl  text-[#000] font-[Gilroy-Bold] flex justify-center items-baseline gap-x-1">
                  <span className="text-5xl lg:text-6xl"> $0</span>
                  /month
                </p>
              </div>
              <div className="space-y-2 my-7">
                <div className="flex items-center gap-x-2">
                  <img src="./check.svg" alt="" />
                  <p className="font-light text-[#808080] text-center text-lg">
                    Unlimited job alerts
                  </p>
                </div>
                <div className="flex items-center gap-x-2">
                  <img src="./check.svg" alt="" />
                  <p className="font-light text-[#808080] text-center text-lg">
                    Unlimited job alerts
                  </p>
                </div>
              </div>
              <button className="flex items-center justify-center gap-x-2 w-[12rem] md:w-[12rem]font-semibold  text-sm custom-gradient transition-colors duration-300  px-2 py-4 rounded-md hover:text-[#800080] text-[#FFF] font-semibolds uppercase hover:bg-[#c0c5c5] mx-auto ">
                CREATE AN ACCOUNT
              </button>
            </div>
            <div className="flex flex-col items-center justify-center bg-[#fff] pt-5 pb-10 rounded-md shadow">
              <div className="w-full text-center space-y-2 border-b px-8 py-4">
                <h1 className="text-xs sm:text-base lg:text-xl font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                  BASIC PLAN
                </h1>{" "}
                <p className="text-xs sm:text-base lg:text-xl  text-[#000] font-[Gilroy-Bold] flex justify-center items-baseline gap-x-1">
                  <span className="text-5xl lg:text-6xl"> $50</span>
                  /month
                </p>
              </div>
              <div className="space-y-2 my-7">
                <div className="flex items-center gap-x-2">
                  <img src="./check.svg" alt="" />
                  <p className="font-light text-[#808080] text-center text-lg">
                    Unlimited job alerts
                  </p>
                </div>
                <div className="flex items-center gap-x-2">
                  <img src="./check.svg" alt="" />
                  <p className="font-light text-[#808080] text-center text-lg">
                    Unlimited job alerts
                  </p>
                </div>
              </div>
              <button className="flex items-center justify-center gap-x-2 w-[12rem] md:w-[12rem]font-semibold  text-sm custom-gradient transition-colors duration-300  px-2 py-4 rounded-md hover:text-[#800080] text-[#FFF] font-semibolds uppercase hover:bg-[#c0c5c5] mx-auto ">
                SELECT BASIC PLAN
              </button>
            </div>
            <div className="flex flex-col items-center justify-center bg-[#fff] pt-5 pb-10 rounded-md shadow">
              <div className="w-full text-center space-y-2 border-b px-8 py-4">
                <h1 className="flex items-center justify-center gap-x-2  text-xs sm:text-base lg:text-xl font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent w-full">
                  PREMIUM PLAN
                  <button className=" font-semibold  text-sm bg-[#20BF6F] transition-colors duration-300  p-2  rounded-md hover:text-[#800080] text-[#FFF] font-semibolds  hover:bg-[#c0c5c5] ">
                    Recommended
                  </button>
                </h1>{" "}
                <p className="text-xs sm:text-base lg:text-xl  text-[#000] font-[Gilroy-Bold] flex justify-center items-baseline gap-x-1">
                  <span className="text-5xl lg:text-6xl"> $340</span>
                  /month
                </p>
              </div>
              <div className="space-y-2 my-7">
                <div className="flex items-center gap-x-2">
                  <img src="./check.svg" alt="" />
                  <p className="font-light text-[#808080] text-center text-lg">
                    Unlimited job alerts
                  </p>
                </div>
                <div className="flex items-center gap-x-2">
                  <img src="./check.svg" alt="" />
                  <p className="font-light text-[#808080] text-center text-lg">
                    Unlimited job alerts
                  </p>
                </div>
              </div>
              <button className="flex items-center justify-center gap-x-2 w-[12rem] md:w-[12rem]font-semibold  text-sm custom-gradient transition-colors duration-300  px-2 py-4 rounded-md hover:text-[#800080] text-[#FFF] font-semibolds uppercase hover:bg-[#c0c5c5] mx-auto ">
                SELECT PREMIUM PLAN
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Tagline / infinte scrolling *********************************** */}
      <section className="mx-auto  bg-[#FFF] py-10 lg:py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center space-y-5 ">
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-4xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
          GigShark Alerts & Automation{" "}
        </h2>
        <marquee behavior="" direction="">
          <h2 className="text-xl bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9] opacity-70 bg-clip-text text-transparent text-center md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em]">
            - Empowering Freelancers and Agencies To Do Their Best Work
          </h2>
        </marquee>
      </section>

      {/* Banner ************************************/}
      <div
        className="w-full bg-center bg-cover lg:h-[35rem] "
        style={{ "background-image": "url(./wcubg.svg)" }}
      >
        <div className="container m-auto space-y-6 md:space-y-0 lg:flex md:gap-6 justify-end lg:items-end p-3 md:py-28 md:px-20 bg-[#800080] lg:bg-transparent ">
          <div className="lg:w-6/12">
            <h2 className="text-xl text-[#FFF] font-medium md:text-4xl lg:text-5xl font-[Gilroy-Bold] lg:tracking-[.10em] text-center lg:text-left">
              Ready to Dive into the Gig Economy?
            </h2>
            <p className="mt-3 lg:mt-6 font-extralight text-[#FFF] text-sm md:text-xl text-center lg:text-left">
              Join GigShark today and take the freelancing world by storm! Let
              our AI handle the job hunt while you ive straight into what you do
              best. No more guesswork - just pure, unstoppable hustle. Get in,
              get noticed, and get paid!
            </p>
            <div className="flex">
              <button className="w-[15rem] flex justify-center mt-7 text-sm  transition-colors duration-300 p-px  rounded-md  bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  font-bold uppercase hover:bg-[#F5F5F5]">
                <div className="bg-white w-full p-3 rounded-md">
                  <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GET STARTED FOR FREE
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Email Sub *********************************** */}
      <section className="mx-auto  bg-[#FFF] py-20 px-2 lg:px-24  flex flex-col  items-center  justify-center lg:space-y-5 ">
        <h2 className="text-2xl text-[#000] font-[Gilroy-Bold]  text-center md:text-4xl lg:text-5xl mx-auto lg:tracking-[.10em]">
          Be the first to know about
        </h2>
        <h2 className="text-2xl text-[#1A1A1A] text-center md:text-3xl lg:text-5xl mx-auto font-[Gilroy-Bold] lg:tracking-[.10em] sm:w-[40rem] lg:w-[48rem] ">
          <span className="bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
            new features
          </span>
        </h2>
        <p className="mt-5 text-center font-light text-[#62747d] text-sm px-6 lg:px-0 sm:w-[40rem] lg:w-[48rem] mx-auto ">
          Enter your email address to stay ahead of others by getting timely
          updates on new features.
        </p>
        <div className="flex flex-col lg:flex-row w-[17rem] gap-3 lg:gap-0 justify-center items-center  lg:w-[33rem] my-2">
          <input
            // value={email}
            type="email"
            placeholder="Email Address"
            // onChange={(e) => {
            //   setEmail(e.target.value);
            // }}
            className="block w-full font-extralight placeholder-[#808080] rounded-md border border-gray-[#808080] bg-white px-2.5 py-3 text-[#808080] focus:outline-none required"
          />
          <button className="lg:-ml-3 flex items-center justify-center gap-x-2 w-[12rem] md:w-[12rem]font-semibold  text-sm custom-gradient  transition-colors duration-300  px-2 py-3 rounded-r-md hover:text-[#800080] text-[#FFF] font-semibolds uppercase hover:bg-[#c0c5c5] mx-auto ">
            SUBSCRIBE
            <img src="./send.svg" alt="" />
          </button>
        </div>
      </section>

      {/* Blog ************** **************** */}
      <div
        id="blog"
        className=" bg-[#F3F4FE] py-16 px-4 lg:px-24  flex flex-col  items-center  justify-center gap-x-1 "
      >
        <div className="container  mx-auto py-10">
          <h2 className="text-2xl text-[#000] font-[Gilroy-Bold]  pb-10 text-center md:text-5xl lg:w-[35rem] mx-auto">
            Blog Posts & Articles
          </h2>
          <div className=" grid grid-cols-1 gap-8 md:gap-3 lg:gap-8 md:grid-cols-3 lg:grid-cols-3">
            <div>
              <img
                className="object-cover object-center w-full h-64 rounded-t-lg lg:h-80"
                src="./pic5.png"
                alt=""
              />

              <div className="p-4 bg-[#fff]">
                <div className="flex items-center gap-x-2">
                  <h1 className="text-xs sm:text-sm font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GIG ECONOMY
                  </h1>{" "}
                  -
                  <p className="text-xs sm:text-sm  text-[#808080] font-semibold flex items-center gap-x-1">
                    <span className="bg-[#7E7E7E] rounded-full h-1 w-1"></span>
                    15 AUGUST
                  </p>
                </div>
                <h1 className="mt-4 text-xl font-semibold text-gray-800">
                  Everything You Need to Know About the World of Freelancing
                </h1>
              </div>
            </div>
            <div>
              <img
                className="object-cover object-center w-full h-64 rounded-t-lg lg:h-80"
                src="./pic7.png"
                alt=""
              />

              <div className="p-4 bg-[#fff]">
                <div className="flex items-center gap-x-2">
                  <h1 className="text-xs sm:text-sm font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GIG ECONOMY
                  </h1>{" "}
                  -
                  <p className="text-xs sm:text-sm  text-[#808080] font-semibold flex items-center gap-x-1">
                    <span className="bg-[#7E7E7E] rounded-full h-1 w-1"></span>
                    15 AUGUST
                  </p>
                </div>
                <h1 className="mt-4 text-xl font-semibold text-gray-800">
                  Everything You Need to Know About the World of Freelancing
                </h1>
              </div>
            </div>
            <div>
              <img
                className="object-cover object-center w-full h-64 rounded-t-lg lg:h-80"
                src="./pic8.png"
                alt=""
              />

              <div className="p-4 bg-[#fff]">
                <div className="flex items-center gap-x-2">
                  <h1 className="text-xs sm:text-sm font-semibold bg-gradient-to-br  from-[#6E5DE9] via-[#A159F2] to-[#CD55F9]  bg-clip-text text-transparent">
                    GIG ECONOMY
                  </h1>{" "}
                  -
                  <p className="text-xs sm:text-sm  text-[#808080] font-semibold flex items-center gap-x-1">
                    <span className="bg-[#7E7E7E] rounded-full h-1 w-1"></span>
                    15 AUGUST
                  </p>
                </div>
                <h1 className="mt-4 text-xl font-semibold text-gray-800">
                  Everything You Need to Know About the World of Freelancing
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Logining In Modal ********************* */}
      {modal && <LoggingIn />}

      {/* Account Modal ********************* */}
      {accountModal && <Account />}

      {/* Upgrade Modal ********************* */}
      {upgradeModal && <Upgrade />}
    </div>
  );
}

export default Home;
