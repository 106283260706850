import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      className="bg-cover custom-gradient    w-full "
    >
      <div className="lg:px-20  pt-6 px-4 lg:py-12 mx-auto  text-white  md:mb-0   ">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-1 gap-8  md:grid-cols-5 lg:grid-cols-5"
        >
          <div className="items-start col-span-2 mb-5 ">
            <Link className="flex items-center gap-x-2" to="/">
              <img src="../logow.svg" alt="logo" className=" w-[11rem]" />
              {/* <h1 className="font-[Gilroy-Bold] uppercase text-4xl">
                GigShark
              </h1> */}
            </Link>
            <p className="font-extralight text-sm mt-3 text-[#FFF] lg:w-[24rem]">
              Lorem ipsum dolor sit amet consectetur. Nulla nibh interdum non
              habitant tincidunt massa. Nunc scelerisque enim interdum vitae
              vulputate vestibulum orci. In ultrices amet nibh mattis faucibus
              lacinia dui. Velit.
            </p>
          </div>

          <div className="items-start col-span-1">
            <p className=" text-base font-bold mb-3">Features</p>
            <div className="flex flex-col items-start  space-y-2">
              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Chrome Extension
              </Link>

              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Real Time Join Alert
              </Link>

              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                AI Proposal Suggestions
              </Link>
              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Whatsapp Integration
              </Link>
            </div>
          </div>

          <div className="col-span-1">
            <p className=" text-base font-bold mb-3">Company</p>
            <div className="flex flex-col items-start  space-y-2">
              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Pricing
              </Link>

              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Our Data
              </Link>

              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                About Us
              </Link>
            </div>
          </div>

          <div className="col-span-1">
            <p className=" text-base font-bold mb-3">Legal</p>
            <div className="flex flex-col items-start  space-y-2">
              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Terms
              </Link>

              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Privacy Policy
              </Link>

              <Link
                to=""
                className="text-[#F5F5F5] font-extralight text-sm transition-colors duration-300  hover:text-gray-300"
              >
                Cookie Policy
              </Link>
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="lg:flex pt-5 items-center pb-10 justify-between px-4 lg:px-20"
      >
        <p className="text-sm font-light text-[#fff] ">
          © {currentYear} GigShark Limited. All rights reserved.
        </p>
        <div className="mt-2 flex  gap-x-3 items-center ">
          <a href="##" className=" outline-none focus:outline-none ">
            <img src="./social1.svg" alt="" />
          </a>
          <a href="##" className=" outline-none focus:outline-none ">
            <img src="./social2.svg" alt="" />
          </a>
          <a href="##" className="outline-none focus:outline-none ">
            <img src="./social3.svg" alt="" />
          </a>
          <a href="##" className="outline-none focus:outline-none ">
            <img src="./social4.svg" alt="" />
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default Footer;
